// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/img/triangles.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#beneficiary {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  height: 15vh;
  padding: 8px;
  width: 100%;
}
section#beneficiary div.bank_card_picture {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0px auto !important;
}
section#beneficiary div.bank_card_picture img {
  border-radius: 12px;
  height: 4rem;
  width: 4rem;
  margin: 0.6rem;
}
section#beneficiary div.info {
  flex: 4;
  margin: 0px;
}
section#beneficiary div.info span.name {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  margin: auto;
  text-align: center;
}
section#beneficiary div.info span.iban {
  color: #E4E5EB;
  font-family: "Source Sans Pro";
}
section#beneficiary div.info span.balance {
  color: #0681b1;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}
section#beneficiary p.send_button {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 3rem;
  margin: 0px;
  margin-right: 16px;
  margin-top: -8px;
}
section#beneficiary .lds-ring {
  display: inline-block;
  position: relative;
  width: 56px;
  height: 56px;
  margin-right: 16px;
}
section#beneficiary .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 4px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
section#beneficiary .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
section#beneficiary .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
section#beneficiary .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
section#beneficiary #arrowAnim {
  width: 56px;
  height: 56px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
section#beneficiary .arrow {
  width: 25px;
  height: 25px;
  border: 2px solid;
  border-color: #dfe0e2 transparent transparent #dfe0e2;
  transform: rotate(135deg);
}
section#beneficiary .arrowSliding {
  position: absolute;
  animation: slide 2s linear infinite forwards;
  opacity: 0;
}
section#beneficiary .delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
section#beneficiary .delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
section#beneficiary .delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  20% {
    opacity: 1;
    transform: translateX(-9px);
  }
  80% {
    opacity: 1;
    transform: translateX(9px);
  }
  100% {
    opacity: 0;
    transform: translateX(15px);
  }
}

section#beneficiary.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

section#beneficiary:hover {
  background-color: rgba(255, 255, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/beneficiary/beneficiary.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,0CDsBwB;ECrBxB,yDAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAIF;AAFE;EACE,mBAAA;EACA,aAAA;EACA,OAAA;EACA,uBAAA;EACA,2BAAA;AAIJ;AAFI;EACE,mBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AAIN;AAAE;EACE,OAAA;EACA,WAAA;AAEJ;AAAI;EACE,mDDuEqB;ECtErB,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;AAEN;AACI;EACE,cDpBa;ECqBb,8BD+DkB;AC9DxB;AAEI;EACE,cDTgB;ECUhB,mDD4DuB;AC5D7B;AAIE;EACE,mDDoDuB;ECnDvB,gBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAKE;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAME;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,8DAAA;EACA,sDAAA;AAJJ;AAOE;EACE,uBAAA;AALJ;AAQE;EACE,sBAAA;AANJ;AASE;EACE,uBAAA;AAPJ;AAUE;EACE;IACE,uBAAA;EARJ;EAWE;IACE,yBAAA;EATJ;AACF;AAYE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAVJ;AAaE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,qDAAA;EACA,yBAAA;AAXJ;AAcE;EACE,kBAAA;EACA,4CAAA;EACA,UAAA;AAZJ;AAeE;EACE,2BAAA;EACA,mBAAA;AAbJ;AAgBE;EACE,2BAAA;EACA,mBAAA;AAdJ;AAiBE;EACE,2BAAA;EACA,mBAAA;AAfJ;AAkBE;EACE;IACE,UAAA;IACA,4BAAA;EAhBJ;EAmBE;IACE,UAAA;IACA,2BAAA;EAjBJ;EAoBE;IACE,UAAA;IACA,0BAAA;EAlBJ;EAqBE;IACE,UAAA;IACA,2BAAA;EAnBJ;AACF;;AAuBA;EACE,0CD5IwB;ACwH1B;;AAuBA;EACE,0CDhJwB;AC4H1B","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
