// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./banka-contact.scss"></require>
  <require from="../../components/date-format-full"></require>
  <section class="flex-row" id="banka_contact">
    <div class="pic">
      <img src.bind="userDetails.urlPic" if.bind="userDetails.urlPic && userDetails.urlPic != 'undefined'">
      <img class="default" src="${___HTML_LOADER_IMPORT_0___}" if.bind="!userDetails.urlPic || userDetails.urlPic == 'undefined'">
    </div>
    <p>
      <span>\${userDetails.displayName}</span><br>
      <span class="subtitle">\${userDetails.email}</span>
    </p>
    <div class="flex-grow"></div>
    <div class="buttons flex-row">
      <button class="btn" click.delegate="accept({request: contact})" show.bind="accept">
        <i class="fas fa-check color-primary"></i>
      </button>
      <button class="btn" click.delegate="refuse({request: contact})" show.bind="refuse">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </section>
</template>
`;
// Exports
export default code;