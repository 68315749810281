// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/audio/sent.mp3", import.meta.url);
// Module
var code = `<template>
  <require from="./animated_checkmark.scss"></require>
  <section id="animated_checkmark" class="column" if.bind="isCheckmarkShowing">
    <audio autoplay src="${___HTML_LOADER_IMPORT_0___}"></audio>
    <div class="success-checkmark">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div>
  </section>
</template>`;
// Exports
export default code;