import { bindable } from 'aurelia-framework';
import { YapilyAccountModel } from 'components/models/YapilyModels/YapilyAccountModel';
import { YapilyBankDetailsModel } from 'components/models/YapilyModels/YapilyBankDetailsModel';

export class Beneficiary {
    @bindable private beneficiary: YapilyAccountModel;
    @bindable private bank: YapilyBankDetailsModel;
    @bindable private isSelected: boolean = true;
    @bindable private canSend: boolean = false;
    @bindable private wantsToPay: boolean = false;
    @bindable private callback;
    @bindable private loading: boolean;

    constructor() {
    }

    bind() {
        // console.log("inside component benef")
    }
}