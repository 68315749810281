export class IbanConverterValueConverter {
    toView(value) {
        if (value == undefined) {
            return ""
        }
        if (value.length < 16) {
          return value;
        }
        value = value.replace(/\s+/g, ''); //Removes previous spaces
        let valueConverted = "";
        for (let i = 0; i < value.length; i++) {
            valueConverted = valueConverted + value[i];
            if ((i + 1) % 4 == 0) {
                valueConverted = valueConverted + " ";
            }
        }
        return valueConverted;
    }

}
