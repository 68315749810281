import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';

@autoinject
export class BankaHeader {
    @bindable private title_header: string = "Title";

    constructor() {
    }
} 