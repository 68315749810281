export class CurrencyDenominationValueConverter {
  toView(value) {
    if(!value)
      return "";
      
    switch(value) {
      case 'GBP':
        return '£';
        break;
      case 'EUR':
        return '€';
        break;
      case 'USD':
        return '$';
        break;
      case 'JPY':
        return '円';
        break;  
      case 'CNY':
        return '元';
        break;     
      case 'BSV':
        return 'BSV';
        break;
      case 'NOK':
        return 'kr';
        break;
      default:
        return value;      
    }
  }
} 
