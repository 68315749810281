// Module
var code = `<template>
  <require from="./divider.scss"></require>
  <section id="divider" class="column">
    <div class="or_container flex-row">
        <div class="line"></div>
        <p t="components.divider.or"></p>
        <div class="line"></div>
    </div>
  </section>
</template>
`;
// Exports
export default code;