// Module
var code = `<template>
  <require from="./currency-formatter.scss"></require>
  <require from="../../number-format-bsv"></require>
  <section id="currency-formatter" class="flex-column">
    <div class="flex-row input_select" style="position: relative;">
      <input id="amountInputState" type="number" ref="caretInput"
      onkeypress="return /[0-9]/i.test(event.key)"
      style="position: absolute; left: 0; z-index: 1; height: 3.5rem;"
      input.trigger="formatInput(\$event, currency)"
      value.bind="inputEmptyState"
      required/>
      <input if.bind="isInputEmpty"  i18n="[placeholder]bank.payment.amount.tabtitle">
      <p id="amountFormatted" class="amountFormatted" style="white-space: nowrap;" contenteditable="true" >\${amountFormatted}</p>
      <p id="currency"  class="currency" if.bind="currency">\${currency}</p>
    </div>
  </section>
</template>
`;
// Exports
export default code;