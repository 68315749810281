// Module
var code = `<template>
  <section id="card-banka" click.delegate="router.navigateToRoute('choose_account')">
    <div class="bank_card_picture">
      <img src.bind="picture" alt="">
    </div>
    <div class="bank_card_content flex-column">
      <div class="bank_card_name">
        <p title.bind="name">\${name}</p>
      </div>
      <div class="bank_card_number" if.bind="iban">
        <span>\${type}</span><span>\${iban}</span>
      </div>
      <div class="bank_card_amount">
        <p>\${amount} \${currency}</p>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;