import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';

@autoinject
export class AnimatedCheckmark {
  isCheckmarkShowing: boolean = false;
  constructor(
  ) {
  }

  bind() {
      this.showMark()
  }
  showMark() {
      setTimeout(() => {
          this.isCheckmarkShowing = true;
      }, 0);
  }
}
