import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject
export class CardBankaEmpty {
  @bindable private loading: string;

  constructor(private router: Router)  {

  }

  goToAddAccount() {
    this.router.navigateToRoute("choose_type_account")
  } 
}
