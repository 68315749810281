// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/img/triangles.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../static/img/triangles_blue.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#card-banka-detailled {
  aspect-ratio: 27/8.34;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  font-weight: 300;
  justify-content: center;
  margin: 0 1rem;
  height: 100%;
  min-height: 7rem;
  max-height: 14.4rem;
  width: 100%;
  max-width: 27rem;
  padding: 1.15rem 1.5rem;
}
section#card-banka-detailled span#iban {
  cursor: pointer;
  font-size: 80%;
}
section#card-banka-detailled div.bank_card_content {
  height: 100%;
  width: 100%;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type {
  height: 100%;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type img#copyIcon {
  aspect-ratio: 1/1;
  cursor: pointer;
  height: 2rem;
  margin-top: -0.5rem;
  opacity: 0.8;
  padding: 0.5rem;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type i {
  color: #B0FE76;
  margin-left: 0.5rem;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type p#title {
  cursor: pointer;
  font-size: 120%;
  font-weight: 600;
  text-align: left;
  line-height: 1.2rem;
  margin-bottom: 0;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type span {
  color: #999ca1;
  font-family: "Source Sans Pro";
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1rem;
  margin: 0;
  opacity: 0.8;
  text-align: left !important;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type p#amountexpected {
  align-self: flex-start;
  color: #BFBEC2;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
  transition: font-size 0.25s, filter 0.25s;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type p#amount {
  align-self: flex-start;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 0 !important;
  transition: font-size 0.25s, filter 0.25s;
}
section#card-banka-detailled div.bank_card_content div.bank_name_and_type p.outDevice {
  align-self: flex-start;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 0 !important;
  transition: font-size 0.25s, filter 0.25s;
}
section#card-banka-detailled div.picture_and_edit {
  height: 100%;
  width: 20%;
  margin-bottom: 1rem;
}
section#card-banka-detailled div.bank_card_picture {
  background-color: #0f131a;
  align-self: flex-start;
  border-radius: 0.5rem;
  margin-left: auto;
  padding: 0.4rem;
}
section#card-banka-detailled div.bank_card_picture img {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 1.5rem;
  object-fit: scale-down;
  width: 1.5rem;
}
section#card-banka-detailled div.edit_buttons {
  color: #dfe0e2;
  font-size: 1.2rem;
}
section#card-banka-detailled loader-ring {
  height: 1rem;
  margin: auto 0.5rem;
  width: 1rem;
}

section#card-banka-detailled.blue {
  background-color: #0681b1 !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
}
section#card-banka-detailled.blue div.bank_card_picture {
  background-color: #0681b1 !important;
}
section#card-banka-detailled.blue span {
  color: #BFBEC2 !important;
}

@media only screen and (min-width: 60rem) {
  section#card-banka-detailled {
    width: 100%;
  }
  section#card-banka-detailled img#copyIcon {
    aspect-ratio: 1/1;
    cursor: pointer;
    height: 3rem !important;
    margin-top: 0 !important;
    opacity: 0.8;
    padding: 1rem !important;
  }
  section#card-banka-detailled i {
    color: #B0FE76;
    margin-top: 1rem;
    margin-left: 1rem !important;
  }
  section#card-banka-detailled div.bank_card_content div.bank_name_and_type p#title {
    font-size: 1.5rem;
  }
  section#card-banka-detailled div.bank_card_content div.bank_name_and_type span {
    cursor: pointer;
    font-size: 1rem !important;
    margin-top: 1rem;
  }
  section#card-banka-detailled div.bank_card_content div.bank_name_and_type p#amount {
    font-size: 2rem !important;
  }
  section#card-banka-detailled div.bank_card_content div.bank_name_and_type p.outDevice {
    font-size: 1.5rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/card-banka-detailled/card-banka-detailled.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,qBAAA;EACA,mBAAA;EACA,0CDoBwB;ECnBxB,yDAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EAEA,gBAAA;EACA,uBAAA;AAGF;AAAE;EACE,eAAA;EACA,cAAA;AAEJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACI;EACE,YAAA;AACN;AACM;EACE,iBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AACR;AAEM;EACE,cDmBY;ECjBZ,mBAAA;AADR;AAIM;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAFR;AAKM;EACE,cDRW;ECSX,8BD4CgB;EC3ChB,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,YAAA;EACA,2BAAA;AAHR;AAMM;EACE,sBAAA;EACA,cDfiB;ECgBjB,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,yCAAA;AAJR;AAOM;EACE,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,2BAAA;EACA,yCAAA;AALR;AAQM;EACE,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,2BAAA;EACA,yCAAA;AANR;AAYE;EACE,YAAA;EACA,UAAA;EACA,mBAAA;AAVJ;AAaE;EACE,yBD7DqB;EC8DrB,sBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;AAXJ;AAaI;EACE,eAAA;EACA,qBAAA;EACA,cAAA;EACA,sBAAA;EACA,aAAA;AAXN;AAeE;EACE,cD3EgB;EC4EhB,iBAAA;AAbJ;AAgBE;EACE,YAAA;EACA,mBAAA;EACA,WAAA;AAdJ;;AAkBA;EACE,oCAAA;EACA,oEAAA;AAfF;AAiBE;EACE,oCAAA;AAfJ;AAkBE;EACE,yBAAA;AAhBJ;;AAqBA;EACE;IAGE,WAAA;EApBF;EAsBE;IACE,iBAAA;IACA,eAAA;IACA,uBAAA;IACA,wBAAA;IACA,YAAA;IACA,wBAAA;EApBJ;EAuBE;IACE,cDvGc;ICwGd,gBAAA;IACA,4BAAA;EArBJ;EA0BM;IACE,iBAAA;EAxBR;EA0BM;IACE,eAAA;IACA,0BAAA;IACA,gBAAA;EAxBR;EA2BM;IACE,0BAAA;EAzBR;EA2BM;IACE,4BAAA;EAzBR;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
