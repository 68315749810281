// Module
var code = `<template>
    <require from="./beneficiary.scss"></require>
    <require from="../../components/number-format"></require>
    <require from="../../components/ValueConverters/first-letter"></require>
    <require from="../../components/ValueConverters/currency-denomination"></require>
    <require from="../../components/max-length"></require>
    <section id="beneficiary" class="flex-row" class.bind="isSelected ? 'selected' : ''" if.bind="beneficiary">
        <div class="bank_card_picture">
            <img src.bind="bank.media[0].source" if.bind="bank.media[0].source">
            <span if.bind="!bank.media[0].source">\${(beneficiary.name || beneficiary.accountNames[0].name) |
                firstLetter}
            </span>
          </div>
        <div class="info flex-column">
            <span class="name">\${(beneficiary.name  || beneficiary.accountNames[0].name) | maxLength: 22}</span><br
                if.bind="beneficiary.iban || beneficiary.id">
            <span class="iban" if.bind="beneficiary.iban || beneficiary.id">\${beneficiary.iban ||
                beneficiary.id}</span><br if.bind="beneficiary.iban || beneficiary.id">
            <span class="balance" if.bind="beneficiary.balance">\${beneficiary.balance | numberFormat:'0,0.00'}\${beneficiary.currency |
                currencyDenomination }</span>
        </p>

        <div id="arrowAnim" if.bind="canSend && !loading" click.delegate="callback(\$event)">
            <div class="arrowSliding">
                <div class="arrow"></div>
            </div>
            <div class="arrowSliding delay1">
                <div class="arrow"></div>
            </div>
        </div>
        <div if.bind="canSend && loading" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </section>
</template>
`;
// Exports
export default code;