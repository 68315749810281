export class DescriptionCleanerValueConverter {
  toView(value) {
    if (value == undefined) {
      return ""
    }
    else if (value.indexOf('Domiciliation en euros (SEPA) \n') > -1){
      return value.replace('Domiciliation en euros (SEPA) \n', '');
    }
    else if (value.indexOf('Domiciliation en euros (SEPA)') > -1){
      return value.replace('Domiciliation en euros (SEPA)', '');
    }
    else if (value.indexOf('Virement instantané en euros:') > -1){
      return value.replace('Virement instantané en euros:', '');
    }
    else if (value.indexOf('Paiement Bancontact') > -1){
      return value.replace('Paiement Bancontact', '');
    }
    else if (value.indexOf('Achat Maestro') > -1){
      return value.replace('Achat Maestro', '');
    }
    else {
      return value;
    }
  }
} 
