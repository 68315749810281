// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#banka_contact {
  border-radius: 16px;
  padding: 1rem;
  width: 100%;
}
section#banka_contact div.pic img {
  border-radius: 50%;
  height: 3rem;
  object-fit: cover;
  margin-right: 1rem;
  width: 3rem;
}
section#banka_contact div.pic img.default {
  height: 3rem;
  object-fit: contain;
  margin-right: 1rem;
  width: 3rem;
}
section#banka_contact p {
  font-weight: 500;
  margin-bottom: 0rem;
}
section#banka_contact p span.subtitle {
  color: #999ca1;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/banka-contact/banka-contact.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EAEA,aAAA;EACA,WAAA;AAGF;AACI;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACN;AAEI;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AAAN;AAIE;EACE,gBAAA;EACA,mBAAA;AAFJ;AAKI;EACE,cDoBa;ECnBb,iBAAA;AAHN","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
