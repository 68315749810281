import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import './card-banka.scss';

@autoinject
export class CardBanka {
  @bindable private name: string = "";
  @bindable private iban: string = "";
  @bindable private type: string = "";
  @bindable private amount: string = "";
  @bindable private currency: string = "";
  @bindable private picture: unknown = "";
  @bindable private canSelectAccount: boolean = false;

  constructor(private router: Router) {

  }

}
