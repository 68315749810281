import { autoinject, computedFrom, inject, bindable, bindingMode} from 'aurelia-framework';
import { State } from "state";

@autoinject()
@inject(Element)

export class CurrencyFormatter {
 
  @bindable({ defaultBindingMode: bindingMode.twoWay }) amount: number;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) amountFormatted: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) currency: string;

  displayText: string;
  state: State;
  selectedCurrency: string;
  errorMessage: string;

  input: number
  output: number

  @bindable inputId: string;
  @bindable targetId: string;

  constructor(private element: Element) {}

  @computedFrom('currency')
  get currencySymbol(): string {
    switch (this.currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'BSV':
        return '₿';
      case 'GBP':
        return '£';
      default:
        return '';
    }
  }

  formatEUR(number) {
    return number.toLocaleString("de-DE", { style: "decimal", minimumFractionDigits: 2 });
  }

  formatUSD(number) {
    return number.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2 });
  }
  formatGBP(number) {
    return number.toLocaleString("en-GB", { style: "decimal", minimumFractionDigits: 2 });
  }

  formatInput(event, currency) {
    let inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
  
    // Limit input length
    if (inputValue.length > 16) {
      inputValue = inputValue.slice(0, 16);
      inputElement.value = inputValue;
    }

    // Limit input length
    if (inputValue === "") {
      inputValue = "0"
      inputElement.value = inputValue;
    }
  
    // Check if the input value is a number
    if (!/^\d*(\.\d+)?$/.test(inputValue.replace(/ +/g, ""))) {
      inputElement.value = '';
    } else {
      // Append new input to existing value
      let inputValueFloat = parseFloat(inputValue.replace(/ +/g, ""));

      // Update amount with the input value
      this.amount = inputValueFloat;
      // Handle the input based on the selected currency
      switch (currency) {
        case 'EUR': {
          this.amountFormatted = this.formatEUR((inputValueFloat / 100));
          break
        }
        case 'BSV': {
          this.amountFormatted = (inputValueFloat / 100000000).toFixed(8);
          // let beautifulAmountFormatted = "";
          // for (let i = this.amountFormatted.length - 1; i > 0; i--) {
          //   beautifulAmountFormatted = this.amountFormatted.charAt(i) + beautifulAmountFormatted ;
          //   if(i == this.amountFormatted.length -3)
          //     beautifulAmountFormatted = " " + beautifulAmountFormatted;
          //   if(i == this.amountFormatted.length -6)
          //     beautifulAmountFormatted = " " + beautifulAmountFormatted;
          //   if(i == this.amountFormatted.length -12)
          //     beautifulAmountFormatted = " " + beautifulAmountFormatted;
          //   if(i == this.amountFormatted.length -15)
          //     beautifulAmountFormatted = " " + beautifulAmountFormatted;
          // }
          // this.amountFormatted = beautifulAmountFormatted;
          break
        } 
        case 'GBP': {
          this.amountFormatted = this.formatGBP((inputValueFloat / 100));
          break
        }
        case 'USD': {
          this.amountFormatted = this.formatUSD((inputValueFloat / 100));
          break;
        }
        default:
          // handle other cases
          break;
      }
    }
  }

  @bindable inputEmptyState: string = '';

  @computedFrom('inputEmptyState')
  get isInputEmpty(): boolean {
    return this.inputEmptyState.trim() === '';
  }

  // private caretInput: HTMLInputElement;

  // attached() {
  //   // This lifecycle hook is called when the component is attached to the DOM
  //   this.caretInput.addEventListener('focus', this.moveCaretRight);
  // }

  // detached() {
  //   // This lifecycle hook is called when the component is detached from the DOM
  //   this.caretInput.removeEventListener('focus', this.moveCaretRight);
  // }

  // moveCaretRight = (event: FocusEvent) => {
  //   const input = event.target as HTMLInputElement;
  //   const newPosition = input.value.length + 8; // Move the caret 8 positions to the right
  //   input.setSelectionRange(newPosition, newPosition);
  // }
}
