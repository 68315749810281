import { YapilyAccountIdentificationModel } from "./../models/YapilyModels/YapilyAccountIdentificationModel";
import { RecipientsHttpClient } from "./../../http_clients/RecipientsHttpClient";
import { I18N } from "aurelia-i18n";
import {
  autoinject,
  bindable,
  observable,
  BindingEngine,
} from "aurelia-framework";
import { Router } from "aurelia-router";
import { P2PWallet } from "bsv-wallet";
import { WalletsManager, WalletInfo } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { User } from "components/models/UserModel";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";

@autoinject
export class cardBankaDetailled {
  @bindable private address: string = "";
  @bindable private name: string = "";
  @bindable private iban: string = "";
  @bindable private type: string = "";
  @bindable private amount: number;
  @bindable private expected: string = "";
  @bindable private currency: string = "";
  @bindable private picture: unknown = "";
  @bindable private canSelectAccount: boolean = false;
  @bindable private blue: boolean = false;
  @bindable private editable: boolean = false;
  @bindable private crypto: boolean = false;
  @bindable private outOfDevice: boolean = false;
  @bindable private id: string;
  @bindable private blurred: boolean = false;
  // @bindable private bank: any;
  // @bindable private account: any;
  @bindable private anonymate: boolean = false; //If informations must be hided for anonymity
  @bindable private loadingAmount: boolean;
  @bindable private loader: boolean;
  @bindable private paymail: string;
  private noname: string = "";
  private owner_id: string;
  private me: User;
  private isOutOfDevice;
  private outOfDeviceWallet;
  private isCopied: boolean = false;
  private localeForCurrency;

  constructor(
    private router: Router,
    private i18n: I18N,
    private recipientshttpclient: RecipientsHttpClient,
    private paymailWalletHttpClient: PaymailWalletClient,
    private singleton: SingletonService,
    private bindingEngine: BindingEngine
  ) {
    this.bindingEngine.propertyObserver(this, "loader").subscribe(() => {
      console.log("change has been noticed");
      console.log(this.loader);
    });
  }
  attached() {
    try {
      this.outOfDeviceWallet = this.singleton.getOutOfDeviceWallet();
      console.log(this.outOfDeviceWallet);
      if (this.outOfDeviceWallet) {
        this.isOutOfDevice = true;
        this.outOfDevice = true;
      } else {
        this.isOutOfDevice = false;
        this.outOfDevice = false;
      }
    } catch (e) {
      console.log(this.outOfDevice + "CATCH ERROR");
    }

    this.me = this.singleton.getMe();
  }

  async bind() {
    this.getLocaleForCurrency();
    if (this.crypto) this.currency = "BSV";
    this.noname = this.i18n.tr("components.card_banka_detailled_no_name");

    /*   this.amount = await this.getBalanceFromWallet(); */
    //if (this.amount === 0) this.amount = 0.0;
  }

  // async getBalanceFromWallet() {
  //   let user = this.singleton.getMe();
  //   this.owner_id = user._id;
  //   let request = await this.paymailWalletHttpClient.fetch(
  //     `/wallets/${this.owner_id}/balance`
  //   );
  //   let is200Ok = await checkResponseStatus(request);
  //   let balance = await is200Ok.json();

  //   return balance.totalAmount;
  // }

  deleteRecipients() {
    let sure: boolean = confirm(this.i18n.tr("home.alerts.deleteRecipients"));
    if (sure) {
      this.recipientshttpclient.fetch("/recipients/" + this.id, {
        method: "DELETE",
        //delete
      });
    }
  }

  copy() {
    if (this.crypto) {
      navigator.clipboard.writeText(this.paymail);
      console.log("Copied PayMail: " + this.paymail);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    } else {
      navigator.clipboard.writeText(this.iban);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    }
  }

  getLocaleForCurrency() {
    const currentLocale = this.i18n.getLocale();
    switch (currentLocale) {
      case "fr":
        this.localeForCurrency = "fr-FR"; // 1 000 325,23
        break;
      case "de":
        this.localeForCurrency = "de-DE";
        break;
      case "en":
        this.localeForCurrency = "en-US"; // 1,000,325.23$
        break;
      default:
        this.localeForCurrency = "fr-FR";
        break;
    }
  }
}
