// Module
var code = `<template>
  <require from="./card-banka-empty.scss"></require>
  <require from="../loader-ring/loader-ring"></require>
  <section id="card-banka-empty" click.trigger="goToAddAccount()">
    <div  class="bank_empty flex-column" click.trigger="goToAddAccount()">
      <a href="#" click.trigger="goToAddAccount()" t="add_account1"></a> 
      <span>+</span>
    </div>
  </section>
</template>
`;
// Exports
export default code;