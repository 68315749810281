// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./card-banka-detailled.scss"></require>
  <require from="../../components/max-length"></require>
  <require from="../../components/ValueConverters/currency-denomination"></require>
  <require from="../../components/number-format-eur"></require>
  <require from="../../components/number-format-bsv"></require>
  <require from="../../components/ValueConverters/iban-converter"></require>
  <require from="../../components/loader-ring/loader-ring"></require>

  <section id="card-banka-detailled" class="flex-row" class.bind="blue ? 'blue' : 'black'">
    <!-- click.delegate="router.navigateToRoute('choose_account')"   -->
    <div class="bank_card_content flex-column">
      <!--if.bind="name"-->
      <div class="bank_name_and_type flex-column">
        <p id="title" class.bind="blurred == true ? 'blurr' : ''">\${name | maxLength: 22}</p>
        <!-- <p id="title" if.bind="!name" title.bind="name">\${noname | maxLength: 22}</p>  class.bind à enlever pour prod plus tard-->
        <span id="iban" if.bind="!crypto" class.bind="blurred == true ? 'blurr' : ''">\${iban | ibanConverter | maxLength: 34}</span>
        <span id="iban" if.bind="crypto && !paymail">Bitcoin · P2P · SPV</span>
        <span id="paymail" if.bind="crypto && paymail">\${paymail}</span>
        <div class="flex-grow"></div>
        <div class="flex-row">
          <div class="amounts flex-column">
            <p id="amount" if.bind="!crypto" class.bind="blurred == true ? 'blurr' : ''">
              \${(amount < 0) ? '-' : '' } \${currency | currencyDenomination} \${(amount < 0) ? amount*-1 : amount |
                numberFormatEur:localeForCurrency} <span id="amountexpected" if.bind="amount != expected && expected && !crypto"
                class.bind="blurred == true ? 'blurr' : ''">
                (\${(expected < 0) ? '-' : '' } \${currency | currencyDenomination} \${(expected < 0) ? expected*-1 :
                  expected | numberFormatEur:localeForCurrency}) </span>
            </p>
            <p id="amount" if.bind="crypto && !outOfDevice" class.bind="blurred == true ? 'blurr' : ''">
              \${(amount < 0) ? '-' : '' } \${currency | currencyDenomination} \${(amount < 0) ? amount*-1 : amount} <!-- | numberFormatBsv:localeForCurrency-->
            </p>
            <p class="outDevice" if.bind="outOfDevice" i18n="components.card_banka_detailled_out_of_device"></p>
          </div>
          <loader-ring if.bind="loader == true"></loader-ring>
        </div>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="picture_and_edit flex-column">
      <div class="bank_card_picture">
        <img if.bind="picture" src.bind="picture" alt="" click.delegate="copy()"/>
        <img if.bind="!picture && !crypto" src="${___HTML_LOADER_IMPORT_0___}"/>
        <img if.bind="!picture && crypto" id="bsv" src="${___HTML_LOADER_IMPORT_1___}" click.delegate="copy()"/>
      </div>
      <div class="flex-grow"></div>
      <div class="edit_buttons flex-row" if.bind="editable">
        <i class="fas fa-pen" click.delegate="router.navigateToRoute('edit_create_recipients', {recipients_id:id})"></i>
        <div class="flex-grow"></div>
        <i class="fas fa-times" click.delegate="deleteRecipients()"></i>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;