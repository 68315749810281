// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#card-banka-empty {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 10rem;
  margin: auto;
  width: 18rem;
}
section#card-banka-empty p {
  text-align: center;
}
section#card-banka-empty div.bank_empty_loading {
  width: 100%;
}
section#card-banka-empty div.bank_empty_loading i {
  color: #999ca1 !important;
  font-size: 2em;
}
section#card-banka-empty div.bank_empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
section#card-banka-empty div.bank_empty a {
  font-size: large;
  color: #999ca1 !important;
}
section#card-banka-empty div.bank_empty span {
  color: #999ca1;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/card-banka-empty/card-banka-empty.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACCA;EACE,0CAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;EACE,YAAA;EACF,YAAA;AAKF;AAHE;EACE,kBAAA;AAKJ;AAFE;EACE,WAAA;AAIJ;AAHI;EACE,yBAAA;EACA,cAAA;AAKN;AAFE;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AAIJ;AAFI;EACE,gBAAA;EACA,yBAAA;AAIN;AADI;EACE,cDkBa;ECjBb,mDDwEuB;ECvEvB,iBAAA;EACA,gBAAA;AAGN","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
