import moment from 'moment'
import { I18N } from 'aurelia-i18n';
  
export class DateFormatValueConverter {
  private i18n: I18N

  toView(value) {
    return moment(value).format('DD/MM/YYYY · HH:mm');
  }
} 
