import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { User } from './../models/UserModel';
import { UsersHttpClients } from './../../http_clients/UsersHttpClients';
import { SingletonService } from 'singleton';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { FriendModel } from "components/models/FriendModel";

@autoinject
export class BankaContact {
  @bindable private contact: FriendModel;
  @bindable private showbuttons: boolean = false;
  @bindable accept;
  @bindable refuse;
  @bindable selectContact;
  private userDetails: User;
  private isMyRequest: boolean = true;

  constructor(private singleton: SingletonService, private usersHttpClient: UsersHttpClients) {
  }

  bind() {

    this.getContactInfo();
  }

  async getContactInfo() {
    let idToFetch = "";
    if (this.contact.requester_id_fk == this.singleton.getMe()._id) {
      idToFetch = this.contact.friend_id_fk;
    }

    if (this.contact.friend_id_fk == this.singleton.getMe()._id) {
      idToFetch = this.contact.requester_id_fk;
    }

    this.userDetails = await this.getUser(idToFetch)

    console.log(this.userDetails);
  }

  async getUser(idToFetch: string): Promise<User> {
    let request = await this.usersHttpClient.fetch('/profile/' + idToFetch);
    let is200ok = await checkResponseStatus(request);
    let response = await is200ok.json();
    return response;
  }

}
