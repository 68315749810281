// Module
var code = `<template>
    <require from="./banka-header.scss"></require>
    <section id="banka_header">
        <div class="header">
            <h1>\${title_header}</h1>
        </div>
    </section>
</template>`;
// Exports
export default code;